import React, { useState } from 'react'
import { Button, Checkbox, Form as CustomForm, type FormProps, Input, Select, Spin } from 'antd';
import LoadingButton from '../../components/LoadingButton';
import TextField from '../../components/formComponents/TextField';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { client2 } from '../../operationalRequest';
import toastr from "toastr";


type EditAlitiqComponentProp = {
    initialValue?: any,
    onSubmit: any,
    loading: boolean
}
const EditAlitiqComponent: React.FC<EditAlitiqComponentProp> = ({ initialValue, onSubmit, loading }) => {
    const [enableForecast, setEnableForecast] = useState(!!initialValue.alitiq_enable)
    const [form] = CustomForm.useForm();
    const handleCheckboxChange = async (e: CheckboxChangeEvent, deviceId: string, enabled: boolean) => {
        const newCheckedValue = e.target.checked;
        try {
            const response = await client2.put(`api/system-device/${deviceId}/enable-alitiq`, { alitiq_enabled: enabled });
            setEnableForecast(enabled)
            toastr.success("Alitiq status updated successfully");
        } catch (error) {
            console.error("Error updating checkbox value:", error);
            toastr.error("Failed to update Alitiq status");
        }
    };

    return (
        <>
            {!enableForecast ? (
                <>
                    <Checkbox checked={false} onChange={(e) => handleCheckboxChange(e, initialValue.system_device_id, true)}>
                        Enable Forecast
                    </Checkbox>
                </>
            ) : (

                <>
                    <Checkbox className='mb-5' checked={false} onChange={(e) => handleCheckboxChange(e, initialValue.system_device_id, false)}>
                        Disable Forecast
                    </Checkbox>
                    
                    <CustomForm
                        form={form}
                        name="basic"
                        labelCol={{ span: 8 }}
                        initialValues={initialValue}
                        onFinish={onSubmit}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <TextField label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]} />
                        <TextField label="Latitude" name="latitude" type="number" rules={[{ required: true, message: 'Please input latitude!' }]} />
                        <TextField label="Longitude" name="longitude" type="number" rules={[{ required: true, message: 'Please input longitude!' }]} />
                        {/* <TextField label="Location ID" name="location_id" type="string" rules={[{ required: false, message: 'Please input location ID!' }]} /> */}
                        <TextField label="Orientation" name="orientation" type="number" rules={[{ required: true, message: 'Please input orientation!' }]} />
                        <TextField label="Power" name="power" type="number" rules={[{ required: true, message: 'Please input power!' }]} />
                        <TextField label="Inverter Power" name="inverter_power" type="number" rules={[{ required: true, message: 'Please input inverter power!' }]} />
                        <TextField label="Temp Factor" name="temp_factor" type="number" rules={[{ required: true, message: 'Please input temp factor!' }]} />
                        <TextField label="Tilt" name="tilt" type="number" rules={[{ required: true, message: 'Please input tilt!' }]} />
                        <TextField label="Mover" name="mover" type="number" rules={[{ required: true, message: 'Please input mover!' }]} />
                        <TextField label="Installed Power" name="installed_power" type="number" rules={[{ required: true, message: 'Please input installed power!' }]} />
                        <TextField label="Installed Power Inverter" name="installed_power_inverter" type="number" rules={[{ required: true, message: 'Please input installed power inverter!' }]} />
                        <TextField label="Azimuth" name="azimuth" type="number" rules={[{ required: true, message: 'Please input azimuth!' }]} />
                        <CustomForm.Item>
                            <LoadingButton loading={loading} title={"Update"} />
                        </CustomForm.Item>
                    </CustomForm >
                </>

            )}
        </>
    )
}

export default EditAlitiqComponent