import React, { useRef, useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Trash2Icon } from "lucide-react";
import { SearchOutlined } from "@ant-design/icons";
import type { InputRef, TableColumnType } from "antd";
import { Button, Input, Popconfirm, Checkbox } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import toastr from "toastr";
import FilterDatatable from "../../components/datatable/FilterDatatable";
import { client2 } from "../../operationalRequest";
import AddIot from "./AddIot";
import EditIot from "./EditIot";
import {
  CREATE_ALITIQ,
  CREATE_DEVICE,
  DESTROY_DEVICE,
  UPDATE_DEVICE,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import { MANUFACTURE_TYPES, DEVICE_TYPES } from "../../constants/types";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import AddAlitiq from "../alitiq/AddAlitiq";
type SystemName = {
  name: string;
};
type CreatedBy = {
  email: string;
};
interface DataType {
  key: string;
  name: string;
  system: SystemName;
  manufacture_type: any;
  created_by: CreatedBy;
  device_type: any;
  reset: boolean;
  alitiq_enabled: boolean;
}
interface Device {
  id: string;
  name: string;
  description: string;
  manufacture_type: number;
  device_type: number;
  attributes: {
    alitiq_enabled: boolean;
  };
  system_id: number;
  assign_to: number;
  location_id: string;
  location_type: string;
}

type DataIndex = keyof DataType;

const Iots = () => {
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [columnFilter, setColumnFilter] = useState("");
  const filterValue = `filter_${window.location.pathname}`;
  const [isAlitiqEnabled, setIsAlitiqEnabled] = useState(false);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/system-device/${id}`);
    setMutate({ data: Math.random() });
    toastr.success("Device Deleted Successfully");
  };
  const [data, setData] = useState<any[]>([]);

  const fetchData = async () => {
    try {
      const response = await client2.get('api/system-device');
      const fetchedData = response.data.data;

      console.log("Fetched data:", fetchedData);

      setData(fetchedData.map((device: Device) => ({
        ...device,
        alitiq_enabled: device.attributes.alitiq_enabled,
      })));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const handleCheckboxChange = async (e: CheckboxChangeEvent, deviceId: string) => {
    const newCheckedValue = e.target.checked;
    try {
      const response = await client2.put(`api/system-device/${deviceId}/enable-alitiq`, {
        attributes: { alitiq_enabled: newCheckedValue },
      });
      setData((prevData) =>
        prevData.map((device) =>
          device.id === deviceId
            ? { ...device, alitiq_enabled: newCheckedValue }
            : device
        )
      );
      toastr.success("Alitiq status updated successfully");
      await fetchData();
    } catch (error) {
      console.error("Error updating checkbox value:", error);
      toastr.error("Failed to update Alitiq status");
    }
  };



  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setColumnFilter((curr: any) => {
      return {
        ...curr,
        [`${dataIndex}`]: selectedKeys[0],
      };
    });

    const existingFilters = localStorage.getItem(filterValue);
    const searchValue = selectedKeys[0];
    let filterObject = existingFilters ? JSON.parse(existingFilters) : {};
    filterObject[dataIndex] = searchValue;
    localStorage.setItem(filterValue, JSON.stringify(filterObject));
  };

  const filterByType = (
    types: { label: string; value: number }[],
    value: string,
    recordValue: number
  ) => {
    const matchedType = types.find((type) =>
      type.label.toLowerCase().includes(value.toLowerCase())
    );
    return matchedType ? matchedType.value === recordValue : false;
  };
  const renderTypeLabel = (
    types: { label: string; value: number }[],
    value: number
  ) => {
    const type = types.find((type) => type.value === value);
    return type ? type.label : "Unknown";
  };

  const formatKey = (dataIndex: string) => {
    return dataIndex
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  interface DataRecord {
    key: string;
    name: string;
  }




  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${formatKey(dataIndex.replace(/_/g, " "))}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "system") {
        return record.system?.name
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      } else if (dataIndex === "created_by") {
        return record.created_by?.email.toString().includes(value as string);
      } else if (dataIndex === "manufacture_type") {
        return filterByType(
          MANUFACTURE_TYPES,
          value as string,
          record.manufacture_type
        );
      } else if (dataIndex === "device_type") {
        return filterByType(DEVICE_TYPES, value as string, record.device_type);
      }

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) => {
      if (dataIndex === "system") {
        return searchedColumn === dataIndex ? (
          <>{record.system?.name}</>
        ) : (
          record.system?.name
        );
      } else if (dataIndex === "manufacture_type") {
        return renderTypeLabel(MANUFACTURE_TYPES, record.manufacture_type);
      } else if (dataIndex === "created_by") {
        return searchedColumn === dataIndex ? (
          <>{record.created_by?.email}</>
        ) : (
          record.created_by?.email
        );
      } else if (dataIndex === "device_type") {
        return renderTypeLabel(DEVICE_TYPES, record.device_type);
      }
      return searchedColumn === dataIndex ? <>{text}</> : text;
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...getColumnSearchProps("name"),
    },
    // {
    //   title: "Enable Alitiq",
    //   dataIndex: "attributes.alitiq_enabled",
    //   align: "center",
    //   key: "attributes.alitiq_enabled",
    //   render: (alitiq_enabled: boolean | undefined, record: any) => {
    //     const status = record?.attributes?.alitiq_enabled;
    //     return (
    //       <Checkbox checked={status === true} onChange={(e) => handleCheckboxChange(e, record.id)}>
    //         {/* {status === undefined ? "Not Set" : status ? "Enabled" : "Disabled"} */}
    //       </Checkbox>
    //     );
    //   }
    // },
    {
      title: "System",
      dataIndex: "system",
      key: "system",
      sorter: false,
      render: (row: any, record: any) => record.system?.name,
      ...getColumnSearchProps("system"),
    },
    {
      title: "Manufacture",
      dataIndex: "manufacture_type",
      key: "manufacture_type",
      sorter: false,
      render: (row: any, record: any) => {
        const manufactureType = MANUFACTURE_TYPES.find(
          (type) => type.value === record.manufacture_type
        );
        return manufactureType ? manufactureType.label : "Custom";
      },
      ...getColumnSearchProps("manufacture_type"),
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
      ...getColumnSearchProps("created_by"),
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
      sorter: false,
      render: (row: any, record: any) => {
        const deviceType = DEVICE_TYPES.find(
          (type) => type.value === record.device_type
        );
        return deviceType ? deviceType.label : "Unknown";
      },
      ...getColumnSearchProps("device_type"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_DEVICE}>
              <EditIot data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_DEVICE}>
              <Popconfirm
                title="Delete the Device"
                description="Are you sure to delete this device?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="mr-1 mf-1 bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
            {/* Create Alitiq button */}
            {record?.attributes?.alitiq_enabled && (
              <PermissionCheck permission={CREATE_ALITIQ}>
                <AddAlitiq mutate={setMutate} />
              </PermissionCheck>
            )}
          </div>
        );
      },
    },
  ];

  const handleInfo = (values: any) => {
    setLocations(
      values?.data?.data
        .filter((it: any) => {
          return (
            !!it.attributes &&
            it.attributes?.latitude &&
            it.attributes?.longitude
          );
        })
        .map((item: any, i: number) => {
          return {
            lat: item.attributes?.latitude,
            lng: item.attributes?.longitude,
            name: item.name,
          };
        })
    );
  };

  return (
    <Layout title={"Devices"}>
      <FilterDatatable
        setInfo={handleInfo}
        mutate={mutate}
        heading="System Devices"
        addBody={
          <PermissionCheck permission={CREATE_DEVICE}>
            <AddIot mutate={setMutate} />
          </PermissionCheck>
        }
        url="api/system-device"
        columns={columns}
        filters={{ includeCustomers: true }}
        columnFilter={columnFilter}
        setColumnFilter={(updatedFilter: any) => {
          setColumnFilter(updatedFilter);
        }}
      />
    </Layout>
  );
};

export default Iots;
