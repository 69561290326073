import React, { useEffect, useState } from "react";
import { Table, Space, Tag } from "antd";
import { client2 } from "../../../../src/operationalRequest";
import {
  UPDATE_USER,
} from "../../../utils/permission";
import PermissionCheck from "../../../components/authorizeComponent/PermissionCheck";
import EditUser from "./EditUser";
const { Column } = Table;
interface DataType {
  key: React.Key;
  name: {
    first?: string;
    last?: string;
  };
  email?: string;
  loading?: boolean;
  role?: string;
}

interface UserListProps {
  companyId: string;
}

const UserList: React.FC<UserListProps> = ({ companyId }) => {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<DataType[]>([]);
  const [mutate, setMutate] = useState({} as any);
  const fetchData = async () => {
    try {
      const response = await client2.get("/api/users");
      const users = response.data.data?.data;
      const filteredUsers = users.filter((user: any) => user.company_id === companyId);
      const formattedData: DataType[] = filteredUsers.map((user: any, index: number) => ({
        key: index,
        id: user.id,
        permissions: user.permissions,
        name: {
          first: user.firstname || "",
          last: user.lastname || "",
        },
        role: user.role || "",
        email: user.email || "",
        loading: false,
      }));
      setInitLoading(false);
      setData(formattedData);
      setList(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setInitLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const onLoadMore = () => {
    setLoading(true);
    setList(
      data.concat([...new Array(3)].map(() => ({ key: "loading", loading: true, name: {}, email: "" }))),
    );
    fetchData();
    setLoading(false);
    window.dispatchEvent(new Event("resize"));
  };

  return (
    <div>
      <Table<DataType> dataSource={list}>
        {/* <Column
          title="ID"
          dataIndex="id"
          key="id"
          render={(text) => <span>{text || "N/A"}</span>}
        /> */}
        <Column
          title="First Name"
          dataIndex={["name", "first"]}
          key="firstName"
          render={(text) => <span>{text || "Not set"}</span>}
        />
        <Column
          title="Last Name"
          dataIndex={["name", "last"]}
          key="lastName"
          render={(text) => <span>{text || "Not set"}</span>}
        />
        <Column
          title="Email"
          dataIndex="email"
          key="email"
          render={(text) => <span>{text || "N/A"}</span>}
        />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          render={(text) => {
            const color = text === "tenant" || text === "super-admin" ? "blue" : "green";
            return (
              <Tag color={color}>
                <span>{text || "Unknown"}</span>
              </Tag>
            );
          }}
        />
        <Column
          title="Permission"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <PermissionCheck permission={UPDATE_USER}>
                <EditUser data={record} mutate={setMutate} />
              </PermissionCheck>
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default UserList;
