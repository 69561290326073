import React, { useEffect, useState } from "react";
import { Col, Form, Select } from "antd";
import LoadingButton from "../../components/LoadingButton";
import Input from 'antd/es/input/Input';
import TextField from "../../components/formComponents/TextField";
type SetMonthlyCostComponentProps = {
  onSubmit: (values: any, form: any) => void;
  initialValue?: any;
  loading: boolean;
};
const SetMonthlyCostComponent: React.FC<SetMonthlyCostComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = Form.useForm();
  console.log(initialValue, 'hello')
  return (
    <>
      <Form
        form={form}
        name="basic"
        initialValues={initialValue}
        onFinish={(values: any) => onSubmit(values, form)}
        autoComplete="off"
        layout="vertical"
        style={{ width: "300px" }}
      >
        {/* <Form.Item
          label="New Cost"
          name="key"
          rules={[{ required: true, message: "Please input cost!" }]}
        >
          <div className='flex flex-col space-x-1 mt-2'>
            <div className='flex justify-start space-x-3'>
              <Input defaultValue={1} onChange={(e: any) => setCent(e.target.value)} type="number" /> <h4 className='mt-1' >€</h4>
            </div>
          </div>
        </Form.Item> */}
        <Col className="flex justify-start space-x-3"><TextField name="value" type="number" label="Energy Rate" rules={[]} /> <span className="mt-9" >€</span> </Col>
        <Form.Item>
          <LoadingButton loading={loading} title={"Set New Cost"} />
        </Form.Item>
      </Form>
    </>
  );
};

export default SetMonthlyCostComponent;