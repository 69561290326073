import { useState } from "react";
import { client2 } from "../../operationalRequest";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import Layout from "../../components/layouts/Layout";
import AddAlitiq from "./AddAlitiq";
import { Button, Popconfirm } from "antd";
import { CheckCircle, ForwardIcon, Trash2Icon, XCircle } from "lucide-react";
import toastr from "toastr";
import { CREATE_ALITIQ, UPDATE_ALITIQ, DESTROY_ALITIQ } from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import EditAlitiq from "./EditAlitiq"
const Alitiqs = () => {
  const [mutate, setMutate] = useState({} as any);
  const onDelete = async (id: any) => {
    try {
      const response = await client2.delete(`api/alitiq/${id}`);
      toastr.success(response.data.message);
      // Trigger re-fetch of the user list
      setMutate({});
    } catch (error) {
      toastr.error("Failed to delete alitiq. Please try again.");
      console.error(error);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Site Name",
      dataIndex: "name",
      key: "site_name",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            {/* Disable for lack of api endpoint for udpate */}
            {/* <PermissionCheck permission={UPDATE_ALITIQ}>
              <EditAlitiq data={record} mutate={setMutate} />
            </PermissionCheck> */}
            <PermissionCheck permission={DESTROY_ALITIQ}>
              <Popconfirm
                title="Delete the alitiq"
                description="Are you sure to delete this alitiq?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>

          </div>
        );
      },
    },
  ];

  return (
    <Layout title="Alitiqs">
      <div className="flex">
        <OperationalDatatable
          mutate={mutate}
          heading="Alitiqs"
          addBody={
            <PermissionCheck permission={CREATE_ALITIQ}>
              <AddAlitiq mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/alitiq"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Alitiqs;