import React, { useState } from "react";
import { Form, Input } from "antd";
import LoadingButton from "../../components/LoadingButton";
import TextField from "../../components/formComponents/TextField";

type ChangeEmailComponentProps = {
  onSubmit: (values: any, form: any) => void;
  initialValue?: any;
  loading: boolean;
};

const ChangeEmailComponent: React.FC<ChangeEmailComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = Form.useForm();

  return (
    <>
      <p className="text-xs mt-4 text-green-800"></p>
      <Form
        form={form}
        name="change-email"
        initialValues={initialValue}
        onFinish={(values: any) => onSubmit(values, form)}
        autoComplete="off"
        layout="vertical"
        style={{ width: "250px" }}
      >
        {/* <Form.Item
          label="New Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email!",
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            placeholder="Enter new email"
            style={{ height: "42px", fontSize: "16px" }}
          />
        </Form.Item> */}
        <TextField name="email" type="email" rules={[]} label="Email" />

        <Form.Item>
          <LoadingButton loading={loading} title={"Change Email"} />
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangeEmailComponent;
