import React, { useState } from "react";
import toastr from "toastr";
import { client2 } from "../../../operationalRequest";
import ForgetPasswordComponent from "./ForgetPasswordComponent";
import VerificationComponent from "./VerificationComponent";
import NewPasswordComponent from "./NewPasswordComponenet";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState<
    "initial" | "verify" | "newPassword" | "successChange"
  >("initial");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const requestPassword = async (values: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true);
      const response = await client2.post(`api/auth/forget-password`, {
        email: values.email,
      });
      if (response.status === 200) {
        toastr.success("OTP Number sent to you mail successfully!");
        setEmail(values.email);
        setStage("verify");
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async (values: any) => {
    try {
      if (loading) return;
      setLoading(true);
      const verificationResponse = await client2.post(`api/auth/verify-otp`, {
        email,
        otp: values.otp,
      });
      if (verificationResponse.status === 200) {
        toastr.success("OTP verified successfully!");
        setStage("newPassword");
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (values: any) => {
    try {
      if (loading) return;
      setLoading(true);
      console.log("Updating password for email: ", email);
      if (values.password === values.confirmPassword) {
        const response = await client2.post(`api/auth/reset-password`, {
          email,
          password: values.password,
          confirmPassword: values.confirmPassword,
        });
        if (response.status === 200) {
          toastr.success("Password changed successfully!");
          setStage("successChange");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } else {
        toastr.error("Password and Confirm Password don't match!");
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-50 min-h-screen flex items-center justify-center">
      <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
        <div className="px-8">
          {stage === "initial" && (
            <ForgetPasswordComponent
              loading={loading}
              onSubmit={requestPassword}
            />
          )}
          {stage === "verify" && (
            <VerificationComponent
              email={email}
              loading={loading}
              onSubmit={handleVerification}
            />
          )}
          {stage === "newPassword" && (
            <NewPasswordComponent
              email={email}
              loading={loading}
              onSubmit={handlePasswordChange}
            />
          )}
          {stage === "successChange" && (
            <>
              <h2 className="font-bold text-2xl text-green-800">
                Password changed successfully!
              </h2>
              <p>
                Go to <a href="/login">Login</a>
              </p>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default ForgetPassword;
