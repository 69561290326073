import React, { useState } from "react";
import { PlusIcon } from "lucide-react";
import { Modal } from "antd";
import EditAlitiqComponent from "./EditAlitiqComponent";
import { client2 } from "../../operationalRequest";
import toastr from "toastr";

type AddAlitiqProps = {
  mutate?: any;
};

const AddAlitiq: React.FC<AddAlitiqProps> = ({ mutate }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = async (values: any, form: any) => {
    console.log("Submit values: ", values)
    try {
      if (loading) return;
      const response = await client2.post("api/alitiq", {
        ...values,
      });
      mutate({ data: response.data });
      setOpen(false);
      toastr.success("Alitiq Created Successfully");
      form.resetFields();
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <a onClick={() => setOpen(true)}>
        <PlusIcon
          size={30}
          color="white"
          strokeWidth={2}
          className=" rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white"
        />
      </a>
      <Modal
        title={"Add Alitiq"}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: "none" } }}
      >
        <EditAlitiqComponent loading={loading} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default AddAlitiq;