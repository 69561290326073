import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Button, Col, Row, DatePicker, Checkbox, DatePickerProps, Badge } from "antd";
import { client2 } from "../../operationalRequest";
import Chart from 'react-apexcharts';
import SelectTable from "../../components/formComponents/SelectTable";
import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

const Solar2 = () => {
  const [loading, setLoading] = useState(false);
  const [systemType, setSystemType] = useState<any>({});
  const [dateType, setDateType] = useState('day');
  const [date, setDate] = useState('' as any);
  const [devices, setDevices] = useState([] as any);
  const [devicesList, setDevicesList] = useState({} as any);

  const [data, setData] = useState({
    series: [
    //   { name: 'Load', data: [] },
      { name: 'PV Production', data: [] },
      { name: 'Forecast', data: [] },
    //   { name: 'Battery', data: [] },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
      },
      xaxis: {
        categories: [],
        title: {
          text: dateType === 'day' ? 'Hour of Day' : 'Date',
        },
      },
      yaxis: {
        title: {
          text: 'Energy (kW)',
        },
        labels: {
          formatter: (value: any) =>
            parseFloat(value).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        },
      },
      legend: {
        position: 'top',
      },
      colors: ['#FFBF00', '#008FFB', '#32a852', '#a8324e'],
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: (value: any) =>
            parseFloat(value).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        },
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2, 2],
      },
    },
  } as any);

  const fetchData = async (dateType: string) => {
    const response = await client2.post('api/forecast-report?dateType=' + dateType + '&date=' + date, { ...systemType });
    setDevices(response?.data?.devices);
    setDevicesList(response?.data?.devices.map((item: any) => item.id));

    let pvAxis = [] as any; // X-axis labels
    let formattedPvProduction = [] as any;
    let formattedForecast = [] as any;
    
    let text = "Hour of the day";

    if (dateType === 'day') {
      // Initialize default hourly data with zeros
      const defaultData = Array.from({ length: 24 }, (_, i) => ({ hour: i, value: 0 }));

      const fillHourlyData = (data: any) => {
        const mappedData = data ?? {};
        return defaultData.map(({ hour }) => mappedData[hour] ?? 0);
      };

      pvAxis = Array.from({ length: 24 }, (_, i) => `${i}:00`);
      formattedPvProduction = fillHourlyData(response?.data?.data?.pv_production);
      formattedForecast = fillHourlyData(response?.data?.data?.pv_forecast);
     
    } else if (dateType === 'year') {
      const months = {
        '1': 'Jan', '2': 'Feb', '3': 'Mar', '4': 'Apr', '5': 'May', '6': 'Jun',
        '7': 'Jul', '8': 'Aug', '9': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
      } as any;

      pvAxis = Object.keys(response?.data?.data?.pv_production ?? []).map(month => months[month]);
      formattedPvProduction = Object.values(response?.data?.data?.pv_production ?? []);
      formattedForecast = Object.values(response?.data?.data?.load ?? []);
    //   formattedGrid = Object.values(response?.data?.data?.grid ?? []);
    //   formattedBattery = Object.values(response?.data?.data?.battery ?? []);
      text = "Months";
    }

    setData((prevState: any) => ({
      ...prevState,
      series: [
        // { name: 'Load', data: formattedLoad },
        { name: 'PV Production', data: formattedPvProduction },
        { name: 'Forecast', data: formattedForecast },
        // { name: 'Battery', data: formattedBattery },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: pvAxis,
          title: {
            text: text,
          },
        },
      },
    }));
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(dateString);
  };

  const handleCheckboxChange = (checked: boolean, id: any) => {

    const deviceList = _.isEmpty(systemType?.devices) ? devicesList : systemType.devices
    console.log('dev', deviceList)
    if (checked) {
      // Add id to array
      console.log('hello', id, systemType)
      setSystemType({ ...systemType, devices: Object.values({ ...deviceList, id }) });
      console.log('hello', id, systemType)
    } else {
      // Remove id from array
      const devicesId = deviceList.filter((item: any) => item !== id)

      setSystemType({ ...systemType, devices: Object.values(devicesId) });
    }
  }

  useEffect(() => {
    fetchData(dateType);
  }, [dateType, date, systemType]);






  return (
    <Layout title="Forecast" loading={loading}>
      <div className="px-4 py-2 border rounded-md shadow-md mb-5 w-full">
        <Row className="w-full space-x-3">
          <Col md={6}>
            <SelectTable
              value={systemType?.site}
              placeholder="Select Site"
              table="sites"
              onChange={(val: any) => {
                setSystemType((curr: any) => ({
                  ...curr,
                  site: val,
                  system: undefined,
                  devices: undefined,
                }));
              }}
            />
          </Col>
          {!!systemType?.site && (
            <Col md={6}>
              <SelectTable
                value={systemType?.system}
                filter={[{ table: "system_id", value: systemType?.site }]}
                placeholder="Select System"
                table="systems"
                onChange={(val: any) => {
                  setSystemType((curr: any) => ({ ...curr, system: val }));
                }}
              />
            </Col>
          )}

        </Row>
        <Row className="mt-2">
          <Col md={6} className="flex justify-start space-x-3" >
            {dateType != 'total' && (
              <div className="min-w-32">
                {dateType === 'day' && <DatePicker defaultValue={dayjs().subtract(1, 'day')} onChange={onChange} />}
                {dateType === 'week' && <DatePicker onChange={onChange} picker="week" />}
                {dateType === 'month' && <DatePicker onChange={onChange} picker="month" />}
                {dateType === 'year' && <DatePicker onChange={onChange} picker="year" />}
              </div>
            )}
            {/* <div className="space-x-3 flex justify-start mt-1">
              {['day', 'week'].map((type) => (
                <div
                  key={type}
                  onClick={() => {
                    setDateType(type);
                    setDate('');
                  }}
                  className="cursor-pointer"
                >
                  <span
                    className={`px-2 py-[2px] rounded-lg text-white text-xs 
                ${dateType === type ? 'bg-gray-500' : 'bg-blue-600'} 
                hover:bg-gray-400`}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </span>
                </div>
              ))}
            </div> */}
          </Col>

        </Row>
        <Row>
          <Col>
            <Col className="space-x-2 mt-1">
              {devices.map((item: any, i: number) => {
                return <Checkbox key={i} defaultChecked={devicesList.includes(item.id)} onChange={(e: any) => handleCheckboxChange(e.target.checked, item.id)}>{item.name}</Checkbox>
              })}
            </Col>
          </Col>
        </Row>

      </div>
      <Chart options={data.options as any} series={data.series} type={dateType === 'total' ? 'bar' : 'line'} height={250} />

    </Layout>
  );
};

export default Solar2;
