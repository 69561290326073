import React from 'react';
import Sidebar, { SidebarItem } from './Sidebar';
import { LayoutDashboard, Users, UserCog, Building2, SunIcon, PlugZap2Icon, Bell, LandPlotIcon, Car, MonitorSmartphone, BarChart, Settings, LayoutPanelTop, PercentDiamond, Radar } from 'lucide-react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { READ_API_SETTING, READ_DASHBOARD, READ_DEVICE, READ_FLEET, READ_FORECAST, READ_NOTIFICATION, READ_REPORT, READ_SITE, READ_SUB_TENANT, READ_SYSTEM, READ_TENANT, READ_USER, READ_COMPANY, READ_ALITIQ } from '../../utils/permission';

const SidebarComponent = () => {
  const { user } = useSelector((state: any) => state.user);

  // Group super-admin and support-superadmin together
  const isSuperAdmin = _.includes(user?.role, 'super-admin') || _.includes(user?.role, 'support-superadmin');

  return (
    <Sidebar>
      {isSuperAdmin ? (
        <>
          <SidebarItem icon={<LayoutDashboard size={20} />} permission={READ_DASHBOARD} text="Dashboard" />
          <SidebarItem icon={<Users size={20} />} permission={READ_TENANT} text="Tenants" />
          <SidebarItem icon={<UserCog size={20} />} permission={READ_USER} text="Users" />
          <SidebarItem icon={<Building2 size={20} />} permission={READ_COMPANY} text="Companies" />
          <SidebarItem icon={<Settings size={20} />} text="Settings" permission={READ_API_SETTING}/>
        </>
      ) : (
        <>
          <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" permission={READ_DASHBOARD} />
          <SidebarItem icon={<SunIcon size={20} />} text="Forecast" permission={READ_FORECAST} />
          <SidebarItem icon={<PlugZap2Icon size={20} />} text="Systems" permission={READ_SYSTEM} />
          <SidebarItem icon={<Bell size={20} />} text="Notifications" permission={READ_NOTIFICATION} />
          <SidebarItem icon={<LandPlotIcon size={20} />} text="Sites" permission={READ_SITE} />
          <SidebarItem icon={<Car size={20} />} text="Fleets" permission={READ_FLEET} />
          <SidebarItem icon={<MonitorSmartphone size={20} />} text="Devices" permission={READ_DEVICE} />
          <SidebarItem icon={<BarChart size={20} />} text="Reports" permission={READ_REPORT} />
          <SidebarItem icon={<Settings size={20} />} text="Integrations" permission={READ_API_SETTING} />
          <SidebarItem icon={<LayoutPanelTop size={20} />} text="Sub Tenant" permission={READ_SUB_TENANT} />
          <SidebarItem icon={<PercentDiamond size={20} />} text="Predictions" permission="view-prediction" />
        </>
      )}
    </Sidebar>
  );
};

export default SidebarComponent;