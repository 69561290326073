import { Col, Form, Row, Input } from "antd";
import React, { useState } from 'react';
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import TextField from "../../components/formComponents/TextField";
import { client2 } from "../../operationalRequest";
import SelectTable from '../../components/formComponents/SelectTable';

type InitialValuesType = {
  user_id?: string;
  name?: string;
  site_name?: string;
  latitude?: number;
  longitude?: number;
  location_id?: string;
  orientation?: number;
  power?: number;
  inverter_power?: number;
  temp_factor?: number;
  tilt?: number;
  mover?: number;
  installed_power?: number;
  installed_power_inverter?: number;
  azimuth?: number;
  site_id?: number;
  device_id?: number;
};

type EditAlitiqComponentProps = {
  onSubmit: (values: InitialValuesType, form: any) => void;
  initialValue?: InitialValuesType;
  loading: boolean;
};

const EditAlitiqComponent: React.FC<EditAlitiqComponentProps> = ({ onSubmit, initialValue = {}, loading }) => {
  const [form] = Form.useForm();
  const [siteName, setSiteName] = useState<string>("");
  const handleDeviceChange = async (deviceId: number) => {
    try {
      const response = await client2.get(`/api/system-device/${deviceId}`);
      if (response.status === 404) {
        console.error("Device not found");
        return;
      }
      const device = response.data.data;
      console.log("device", device)
      // Check if the device has an associated site
      if (device && device.deviceable_type.trim() === 'App\\Models\\Site') {
        const siteResponse = await client2.get(`/api/site/${device.deviceable_id}`);
        if (siteResponse.status === 404) {
          console.error("Site not found");
          return;
        }
        const site = siteResponse.data.data;
        setSiteName(site.name);
        form.setFieldsValue({
          site_name: site.name,
          latitude: site.attributes?.latitude !== undefined ? Number(site.attributes.latitude) : 0,
longitude: site.attributes?.longitude !== undefined ? Number(site.attributes.longitude) : 0,
          site_id: site.id,
        });
      } else {
        console.error("The selected device is not associated with a site.");
      }
    } catch (error) {
      console.error("Error fetching device or site details:", error);
    }
  };

  const generateLocationId = () => {
    return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
  };
  return (
    <Form
      form={form}
      name="edit-alitiq-form"
      labelCol={{ span: 24 }}
      initialValues={{
        user_id: initialValue.user_id ?? '',
        name: initialValue.name ?? '',
        site_name: initialValue.site_name ?? '',
        latitude: initialValue.latitude !== undefined ? Number(initialValue.latitude) : 0,
        longitude: initialValue.longitude !== undefined ? Number(initialValue.longitude) : 0,
        location_id: initialValue.location_id ?? String(generateLocationId()),
        orientation: initialValue.orientation !== undefined ? Number(initialValue.orientation) : 0,
        power: initialValue.power !== undefined ? Number(initialValue.power) : 0,
        inverter_power: initialValue.inverter_power !== undefined ? Number(initialValue.inverter_power) : 0,
        temp_factor: initialValue.temp_factor !== undefined ? Number(initialValue.temp_factor) : 0,
        tilt: initialValue.tilt !== undefined ? Number(initialValue.tilt) : 0,
        mover: initialValue.mover !== undefined ? Number(initialValue.mover) : 0,
        installed_power: initialValue.installed_power !== undefined ? Number(initialValue.installed_power) : 0,
        installed_power_inverter: initialValue.installed_power_inverter !== undefined ? Number(initialValue.installed_power_inverter) : 0,
        azimuth: initialValue.azimuth !== undefined ? Number(initialValue.azimuth) : 0,
        site_id: initialValue.site_id !== undefined ? Number(initialValue.site_id) : 0,
        device_id: initialValue.site_id
      }}
      onFinish={(values: InitialValuesType) => {
        const { device_id, ...valuesWithoutDeviceId } = values;
        console.log("Form values before submit:", valuesWithoutDeviceId);
        onSubmit(valuesWithoutDeviceId, form);
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <TextField label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]} />
        </Col>
        <Col span={24}>
          <Form.Item
            name="system_device_id"
            label="Device"
            rules={[{ required: true, message: 'Please select a device!' }]}
          >
            <SelectTable
              table='system_devices'
              placeholder='Select Device'
              onChange={(val: any) => {
                form.setFieldValue('device_id', val);
                handleDeviceChange(val);
              }}
              value={form.getFieldValue('device_id')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="site_id"
            label="Site"
            rules={[{ required: true, message: 'Please select a site!' }]}
          >
            <SelectTable
              table='sites'
              placeholder='Select Site'
              value={form.getFieldValue('site_id')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
        <TextField label="Site Name" name="site_name" rules={[{ required: true, message: 'Please input site name!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Latitude" name="latitude" type="number" rules={[{ required: true, message: 'Please input latitude!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Longitude" name="longitude" type="number" rules={[{ required: true, message: 'Please input longitude!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Location ID" name="location_id" type="string" rules={[{ required: false, message: 'Please input location ID!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Orientation" name="orientation" type="number" rules={[{ required: true, message: 'Please input orientation!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Power" name="power" type="number" rules={[{ required: true, message: 'Please input power!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Inverter Power" name="inverter_power" type="number" rules={[{ required: true, message: 'Please input inverter power!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Temp Factor" name="temp_factor" type="number" rules={[{ required: true, message: 'Please input temp factor!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Tilt" name="tilt" type="number" rules={[{ required: true, message: 'Please input tilt!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Mover" name="mover" type="number" rules={[{ required: true, message: 'Please input mover!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Installed Power" name="installed_power" type="number" rules={[{ required: true, message: 'Please input installed power!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Installed Power Inverter" name="installed_power_inverter" type="number" rules={[{ required: true, message: 'Please input installed power inverter!' }]} />
        </Col>
        <Col span={24}>
          <TextField label="Azimuth" name="azimuth" type="number" rules={[{ required: true, message: 'Please input azimuth!' }]} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditAlitiqComponent;
