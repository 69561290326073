import { Button, Drawer, Tabs } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toastr from "toastr";
import { client2 } from '../../../operationalRequest';
import EditPermissionComponent from './EditPermissionComponent';
type EditUserProps = {
    data: any;
    mutate?: any;
};

const EditUser: React.FC<EditUserProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionList, setPermissionList] = useState<string[]>([]);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editPermission = async (values: any) => {
        try {
            if (loading) return;
            setLoading(true);
            const response = await client2.put(`api/user/permission/${data?.id}`, {
                ...values
            });
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false);
                toastr.success("Permissions Updated Successfully");
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const getPermissions = async () => {
        try {
            const response = await client2.get(`api/user/permission/${data?.id}`);
            if (response.data) {
                setUserPermissions(response.data.data || []);
                //console.log('User Permissions:', response.data.data);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const getAllPermissions = async () => {
        try {
            const response = await client2.get('api/user/permission');
            setPermissionList(response.data.data || []);
            //console.log('All Permissions:', response.data.data);
        } catch (e: any) {
            console.log(e);
        }
    };
    useEffect(() => {
        if (data?.id) {
            getPermissions();
            getAllPermissions();
        }
    }, [data]);

    return (
        <>
            <Button
                onClick={showDrawer}
                size='small'
                className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`}>
                <ArrowBigRightDash />
            </Button>
            <Drawer size='large' title="Edit User" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Permissions',
                            key: '1',
                            children: <EditPermissionComponent loading={loading} initialValue={{ permissions: userPermissions, role: data.role, id: data?.id }} onSubmit={editPermission} />,
                        },
                    ]}
                />
            </Drawer>
        </>
    );
};

export default EditUser;
