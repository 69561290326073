import React, { useState } from 'react';
import { Form, Checkbox, message, Col, Row } from 'antd';
import LoadingButton from '../../../components/LoadingButton';
import { useSelector } from 'react-redux';

type EditPermissionComponentProp = {
  initialValue?: any;
  onSubmit: (values: any) => Promise<void>;
  loading: boolean;
};

interface Permission {
  id: number;
  name: string;
}

interface PermissionGroup {
  [key: string]: Permission[];
}

const permissions: Permission[] = [
  // dashboard
  { id: 1, name: 'read-dashboard' },
  //profile
  { id: 2, name: 'read-profile' },
  // tenant
  { id: 3, name: 'create-tenant' },
  { id: 4, name: 'read-tenant' },
  { id: 5, name: 'update-tenant' },
  { id: 6, name: 'destroy-tenant' },
  // support super admin
  { id: 7, name: 'create-support-superadmin' },
  { id: 8, name: 'read-support-superadmin' },
  { id: 9, name: 'update-support-superadmin' },
  { id: 10, name: 'destroy-support-superadmin' },
  // user
  { id: 11, name: 'create-user' },
  { id: 12, name: 'read-user' },
  { id: 13, name: 'update-user' },
  { id: 14, name: 'destroy-user' },
  // role
  { id: 15, name: 'create-role' },
  { id: 16, name: 'read-role' },
  { id: 17, name: 'update-role' },
  { id: 18, name: 'destroy-role' },
  // company
  { id: 19, name: 'create-company' },
  { id: 20, name: 'read-company' },
  { id: 21, name: 'update-company' },
  { id: 22, name: 'destroy-company' },
  // sub-tenant
  { id: 23, name: 'create-sub-tenant' },
  { id: 24, name: 'read-sub-tenant' },
  { id: 25, name: 'update-sub-tenant' },
  { id: 26, name: 'destroy-sub-tenant' },
  // system
  { id: 27, name: 'create-system' },
  { id: 28, name: 'read-system' },
  { id: 29, name: 'update-system' },
  { id: 30, name: 'destroy-system' },
  // forecast
  { id: 31, name: 'create-forecast' },
  { id: 32, name: 'read-forecast' },
  { id: 33, name: 'update-forecast' },
  { id: 34, name: 'destroy-forecast' },
  // notification
  { id: 35, name: 'read-notification' },
  { id: 36, name: 'update-notification' },
  // site
  { id: 37, name: 'create-site' },
  { id: 38, name: 'read-site' },
  { id: 39, name: 'update-site' },
  { id: 40, name: 'destroy-site' },
  // building
  { id: 41, name: 'create-building' },
  { id: 42, name: 'read-building' },
  { id: 43, name: 'update-building' },
  { id: 44, name: 'destroy-building' },
  // space
  { id: 45, name: 'create-space' },
  { id: 46, name: 'read-space' },
  { id: 47, name: 'update-space' },
  { id: 48, name: 'destroy-space' },
  // storey
  { id: 49, name: 'create-storey' },
  { id: 50, name: 'read-storey' },
  { id: 51, name: 'update-storey' },
  { id: 52, name: 'destroy-storey' },
  // fleet
  { id: 53, name: 'create-fleet' },
  { id: 54, name: 'read-fleet' },
  { id: 55, name: 'update-fleet' },
  { id: 56, name: 'destroy-fleet' },
  // device
  { id: 57, name: 'create-device' },
  { id: 58, name: 'read-device' },
  { id: 59, name: 'update-device' },
  { id: 60, name: 'destroy-device' },
  // report
  { id: 61, name: 'read-report' },
  // api setting
  { id: 62, name: 'create-api-setting' },
  { id: 63, name: 'read-api-setting' },
  { id: 64, name: 'update-api-setting' },
  { id: 65, name: 'destroy-api-setting' },
  // confirguration
  { id: 66, name: 'create-configuration' },
  { id: 67, name: 'read-configuration' },
  { id: 68, name: 'update-configuration' },
  { id: 69, name: 'destroy-configuration' },
  // alitiq
  { id: 70, name: 'create-alitiq' },
  { id: 71, name: 'read-alitiq' },
  { id: 72, name: 'update-alitiq' },
  { id: 73, name: 'destroy-alitiq' },
];

const permissionGroups: PermissionGroup = {
  'API Setting Management': permissions.slice(61, 65),
  'Alitiq Setting Management': permissions.slice(69, 73),
  'Building Management': permissions.slice(40, 44),
  'Company Management': permissions.slice(18, 22),
  'Configuration Management': permissions.slice(65, 69),
  'Dashboard/Profile Management': permissions.slice(0, 2),
  'Device Management': permissions.slice(56, 60),
  'Fleet Management': permissions.slice(52, 56),
  'Forecast Management': permissions.slice(30, 34),
  'Report Management': permissions.slice(60, 61),
  'Role Management': permissions.slice(14, 18),
  'Site Management': permissions.slice(36, 40),
  'Space Management': permissions.slice(44, 48),
  'Storey Management': permissions.slice(48, 52),
  'Sub-Tenant Management': permissions.slice(22, 26),
  'System Management': permissions.slice(26, 30),
  'Tenant Management': permissions.slice(2, 6),
  'User Management': permissions.slice(10, 14),
};

const EditPermissionComponents: React.FC<EditPermissionComponentProp> = ({
  initialValue,
  onSubmit,
  loading,
}) => {
  const [form] = Form.useForm();
  const { role } = useSelector((state: any) => state.role);
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>(initialValue?.permissions || []);

  const handleSubmit = async (values: any) => {
    try {
      await onSubmit({ ...values, permissions: checkedPermissions });
      message.success('Permissions updated successfully!');
    } catch (error) {
      message.error('Error updating permissions');
    }
  };

  const onChange = (checkedValues: string[]) => {
    setCheckedPermissions(checkedValues);
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={{ ...initialValue }}
      onFinish={handleSubmit}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Permissions"
        name="permissions"
        rules={[{ required: true, message: 'Please select at least one permission!' }]}
      >
        <Checkbox.Group value={checkedPermissions} onChange={onChange}>
          <Row gutter={[16, 16]}>
            {Object.keys(permissionGroups).map((group, index) => (
              <Col span={8} key={group}>
                <div>
                  <p className="font-semibold mb-2 text-gray-900">{group}</p>
                  {permissionGroups[group].map((permission: Permission) => (
                    <Col span={24} key={permission.id}>
                      <Checkbox value={permission.name} checked={checkedPermissions.includes(permission.name)}>
                        {permission.name}
                      </Checkbox>
                    </Col>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item>
        <LoadingButton loading={loading} title="Update" />
      </Form.Item>
    </Form>
  );
};

export default EditPermissionComponents;
